/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { List, withStyles } from "@material-ui/core";

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx";
import {Link} from "gatsby";

function Footer({ ...props }) {
  const { classes, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <span className={classes.left}>
          <List className={classes.list}>
            <Link to="/datenschutz">Datenschutz</Link>
            <span> / </span>
            <Link to="/impressum">Impressum</Link>
            <span> / </span>
            <a href="https://instagram.com/gasthaus_hubenstein">instagram</a>
          </List>
        </span>
        <span className={classes.right}>
          &copy; Peter Häring {1900 + new Date().getYear()}, made by Rupert Eisl
        </span>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
