/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "gatsby";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
    return (
    <List className={classes.list}>
        <ListItem className={classes.listItem}>
            <Link to="/stadl" className={classes.navLink}>Wirt's-Stadl</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
            <Link to="/wirtshaus" className={classes.navLink}>Wirtshaus</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
            <Link to="/lieferservice" className={classes.navLink}>Lieferservice</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
            <Link to="/contact" className={classes.navLink}>Kontakt</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
            <Link to="/" className={classes.navLink}>Startseite</Link>
        </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
